import { isEmpty } from 'lodash';
import React, { useState, useRef, useLayoutEffect } from 'react';

interface Props {
  id: string;
  scrollable: boolean;
  scrollBottom: boolean;
  direction: string;
  items: any[];
  onLoad(isLoad: boolean): void;
  children: any;
}

let scrollTimeout: any;

export default function InfiniteList(props: Props) {
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

  const listRef = useRef<any>();

  const handleScroll = (e: any) => {
    const el = e.target;
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      if (props.direction === 'down') {
        setShowScrollButton(Math.abs(el.scrollTop) > 1);
        if (Math.round(Math.abs(el.scrollTop) + el.clientHeight) >= Math.round((el.scrollHeight * 3) / 4)) {
          setLoadMore(true);
        }
      }
      if (props.direction === 'up') {
        setShowScrollButton(Math.round(el.scrollTop + el.clientHeight) < Math.round(el.scrollHeight));
        if (el.scrollTop < 100) {
          setLoadMore(true);
        }
      }
    }, 300);
  };

  const handleScrollToBottom = (e: any) => {
    const list: any = document.getElementById(props.id);
    list.style.scrollBehavior = 'smooth';
    list.scrollTo(0, list.scrollHeight);
    setTimeout(() => {
      list.style.scrollBehavior = 'unset';
    }, 1000);
  };

  useLayoutEffect(() => {
    if (!isEmpty(props.items)) {
      setLoadMore(false);
      props.onLoad(loadMore);
    }
  }, [props.items, loadMore]);

  return (
    <>
      {loadMore && (
        <div className="loading-message">
          <i className="pi pi-spin pi-spinner"></i>
        </div>
      )}
      <ul id={props.id} ref={listRef} onScroll={handleScroll}>
        {props.children}
        {props.scrollBottom && showScrollButton && (
          <div className="scroll-to-bottom" onClick={handleScrollToBottom}>
            <i className="pi pi-angle-double-down"></i>
          </div>
        )}
      </ul>
    </>
  );
}
