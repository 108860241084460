import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Col, Row } from 'react-flexbox-grid';

import TextInput from '../components/TextInput';
import AccountService from '../services/Account';
import { setUser } from '../store/user/actions';
import { isEmpty, toUpper } from 'lodash';

import './Login.scss';

const emptyUser = { email: '', password: '' };

const NewUserSchema = Yup.object().shape({
  email: Yup.string().required('Hãy điền tài khoản'),
  password: Yup.string().required('Hãy điền mật khẩu'),
});

function Login(props: RouteComponentProps) {
  const [errorMsg, setErrorMsg] = useState('');
  const [imei, setImei] = useState('');
  const [selectedUser, setSelectedUser] = useState<any>(emptyUser);

  const dispatch = useDispatch();

  const submitHandler = async (values: any, { setSubmitting }: any) => {
    try {
      const payload = {
        ...values,
        email: toUpper(values.email),
        imei: imei,
      };
      const data = await AccountService.login(payload);
      console.log(data);
      if (data.success) {
        if (!isEmpty(imei)) {
          localStorage.setItem('login-info', JSON.stringify(payload));
        }
        dispatch(setUser(data.data));
      } else {
        setErrorMsg(data.message);
      }
    } catch (error: any) {
      if (error.response.data === 'Bad credentials') {
        setErrorMsg('Incorrect credential.');
      } else {
        setErrorMsg(error.response.data?.message || '');
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const imeiData = localStorage.getItem('imei');
    if (imeiData) {
      setImei(imeiData);
      const loggedInfo = localStorage.getItem('login-info');
      if (loggedInfo) {
        setSelectedUser(JSON.parse(loggedInfo));
      }
    }
  }, []);

  return (
    <div className="login-container">
      <Row className="full-width">
        <Col lg={4} lgOffset={4} md={6} mdOffset={3} xs={10} xsOffset={1} className="content-wrapper">
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="logo-ctn">
                <h1 className="title-2">ĐĂNG NHẬP HỆ THỐNG</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} lgOffset={2} md={8} mdOffset={2} xs={12}>
              <Formik
                initialValues={selectedUser}
                onSubmit={submitHandler}
                enableReinitialize={true}
                validationSchema={NewUserSchema}
              >
                {({ isSubmitting }) => (
                  <Form className="form-group">
                    <Field className="form-control" type="text" name="email" component={TextInput} placeholder="ID" />
                    <Field
                      className="form-control"
                      type="password"
                      name="password"
                      component={TextInput}
                      placeholder="Mật khẩu"
                    />
                    {imei && (
                      <div>
                        SỐ IMEI CỦA BẠN: <span className="text-error">{imei}</span>
                      </div>
                    )}

                    {errorMsg && <div className="text-error">{errorMsg}</div>}

                    <div>
                      <Col className="text-center">
                        {isSubmitting && <i className="pi pi-spin pi-spinner" />}
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          label="Đăng nhập"
                          className="signin-btn fsi-12rem-xs"
                        />
                      </Col>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
